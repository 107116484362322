@import "./src/assets/styles/variables.scss";
@import "bulma/bulma";

#contato {
  font-family: "Roboto Mono";
  background: #f9f9fa;
  padding-top: 100px;
  padding-bottom: 100px;

  .box {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    padding: 50px 60px;
  }

  h1 {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a4a4a;
    margin-bottom: 40px;
  }

  .form {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }

  .label {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
  }

  .input,
  .textarea {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 0px;
    padding-right: 0px;
    resize: none;
    margin-bottom: 15px;

    &:focus {
      box-shadow: none;
      border-radius: 0px;
      border: none;
      background: transparent;
      border-bottom: solid 2px #00b686;
    }
  }

  .validation-message {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: red;
    margin-bottom: 30px;
  }

  .recaptcha {
    margin-top: 30px !important;
  }

  .answer {
    margin-top: 30px;
    margin-bottom: 30px;

    .mdi-icon {
      fill: #4a4a4a;
      width: 22px;
      vertical-align: -5px;
      margin-right: 10px;
    }
  }

  .button {
    margin-top: 0px !important;
    font-family: "Roboto Mono", monospace;
    text-transform: uppercase;
    color: white;
    background-image: linear-gradient(109deg, $verde_get, $azul_get);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    height: 32px;
    line-height: 1;

    &:hover {
      color: white;
    }

    &:focus {
      color: white;
      border-color: transparent !important;
    }
  }

  .como-chegar {
    h1 {
      margin-top: 38px;
    }

    .no-margin-bottom {
      margin-bottom: 0px;
    }

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #4a4a4a;
      letter-spacing: -1.1px;
      margin-top: 5px;
    }

    .description {
      font-size: 18px;
      font-weight: normal;
      color: rgba(0, 0, 0, 0.54);
      letter-spacing: -1.1px;
    }

    hr {
      background-color: #babdbe;
    }

    .more-informations {
      margin-top: 10px;
    }

    .gmaps iframe {
      max-width: 100%;
      height: 300px;
      width: 100%;
    }
  }
}

@include mobile {
  #contato {
    .box {
      padding: 50px 30px;
    }
  }
}

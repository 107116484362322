//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#cartorio {
  .margin-title {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .title {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1.5px;
    text-align: center;
    color: #000000;

    a {
      color: $azul_get;

      &:hover {
        color: $verde_get;
        -webkit-transition: 0.2s ease-in;
        -moz-transition: 0.2s ease-in;
        -o-transition: 0.2s ease-in;
        transition: 0.2s ease-in;
      }
    }
  }

  .margin-bottom-none {
    margin-bottom: 0px !important;
    line-height: 0px !important;
  }

  .padding-bottom-none {
    padding-bottom: 0px !important;
    line-height: 0px !important;
  }

  .site {
    background-color: #414141;
    padding-top: 100px;
    padding-bottom: 100px;

    img {
      border: 2px solid #B9B9BC;
      border-radius: 5px;
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    }
  }

  .margin-full-project {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
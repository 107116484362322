//-------------------
// Variaveis
//-------------------
@import "./src/assets/styles/variables.scss";

#business {
  margin-top: 100px;

  .titulo-section {
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -2.1px;
    text-align: center;
    color: #6bb12e;
    margin-bottom: 50px;
  }

  hr {
    background-color: #babdbe;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .hr-custom {
    margin-top: 55px;
  }

  #qlik {
    margin-top: 55px;

    .lista {
      color: rgba(0, 0, 0, 0.64);
      padding-left: 29px;
    }
  }

  #visao-geral {
    margin-top: 90px;

    .titulo {
      margin-bottom: 50px;
    }
  }

  #cases-de-sucesso {
    padding-top: 100px;
    padding-bottom: 115px;

    .is-vcentered {
      min-height: 150px;
    }

    .titulo {
      margin-bottom: 50px;
    }

    .box {
      background-image: linear-gradient(
        to right,
        rgba(245, 245, 245, 1),
        #ffffff
      );
      border-radius: 4px;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    }

    img {
      max-width: 200px;
      width: 100%;
    }

    .img-div {
      border-right: 2px solid #eceff1;
    }

    .text-div {
      padding-left: 40px;
    }

    .text-cases {
      font-family: $family-second;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}

@media (max-width: 768px) {
  #business {
    #qlik {
      .div-logo {
        text-align: center;
      }

      .logo {
        max-width: 200px;
      }
    }

    #cases-de-sucesso {
      .box {
        padding: 50px;
      }

      img {
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .img-div {
        border: none;
      }

      .text-div {
        padding-left: 15px;
      }
    }
  }
}

//----------------------
// Variables
//------------------------

@import "~bulma/bulma";

//-----------------------
// Fontes
//-----------------------
$family-mono: "Roboto Mono", monospace;
$family-serif: "Roboto", sans-serif;
$family-primary: $family-mono;
$family-second: $family-serif;

//-----------------------
// Cores
//-----------------------
$cinza_get: #4a4a4a;
$azul_get: #448aff;
$verde_get: #00d29b;
$verde_button: #00b686;
$hover: #f5f5f5;
$schole: #f44336;
$spa-icon: #fac04c;
$spa-text: #39ccab;
$disable: #a1a1a1;

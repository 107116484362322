#mais-projetos {
  hr {
    background-color: #babdbe;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  #projetos {
    margin-top: 60px;
    margin-bottom: 100px;

    .title-projetos {
      font-size: 24px;
      text-align: center;
      margin-bottom: 50px;
    }
  }
}
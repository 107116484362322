@import './src/assets/styles/variables.scss';

.navbar {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  background: white;
}

.navbar-link {
  color: $cinza_get;

  &:hover {
    color: $cinza_get !important;
  }
}

.navbar-link:after {
  border-color: $azul_get;
}

.navbar-item {
  font-family: 'Roboto Mono', monospace;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: $cinza_get;

  &:hover {
    color: $cinza_get !important;
    border-color: $azul_get !important;
    background-color: $hover;
  }

  i {
    padding-right: 10px;
  }
}

.navbar .schole {
  color: $schole;

  &:hover {
    color: $schole !important;
  }
}

.navbar .spa {
  color: $spa-text;

  &:hover {
    color: $spa-text !important;
  }

  i {
    color: $spa-icon;
  }
}

.navbar .button {
  font-family: 'Roboto Mono', monospace;
  text-transform: uppercase;
  color: white;
  background-image: linear-gradient(109deg, $verde_get, $azul_get);
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  height: 32px;
  line-height: 1;

  &:hover {
    color: white;
  }

  &:focus {
    color: white;
    border-color: transparent !important;
  }
}

// Navbar Toogle
@media (max-width: 1023px) {
  .navbar-burger {
    height: 64px;
  }

  .navbar-link {
    &:hover {
      background-color: white !important;
    }
  }

  .navbar-item {
    &:hover {
      background-color:white;
    }
  }
}
@import "bulma/bulma";
@import "./src/assets/styles/variables.scss";

#inscricao {
  background-color: #f9f9fa;
  padding-top: 100px;
  padding-bottom: 100px;

  .box {
    padding: 50px 60px;
  }

  h1 {
    font-family: $family-second;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
  }

  h2 {
    font-family: $family-second;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 30px;
  }

  .form {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }

  .label {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.7);
  }

  .input {
    box-shadow: none;
    border-radius: 0px;
    border: none;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding-left: 0px;
    padding-right: 0px;
    resize: none;
    margin-bottom: 15px;

    &:focus {
      box-shadow: none;
      border-radius: 0px;
      border: none;
      background: transparent;
      border-bottom: solid 2px #00b686;
    }
  }

  .validation-message {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: red;
    margin-bottom: 30px;
  }

  .recaptcha {
    margin-top: 10px !important;
  }

  .answer {
    margin-top: 30px;
    margin-bottom: 30px;

    .mdi-icon {
      fill: #4a4a4a;
      width: 22px;
      vertical-align: -5px;
      margin-right: 10px;
    }
  }

  .button {
    margin-top: 0px !important;
    font-family: "Roboto Mono", monospace;
    text-transform: uppercase;
    color: white;
    background-image: linear-gradient(109deg, $verde_get, $azul_get);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    height: 32px;
    line-height: 1;

    &:hover {
      color: white;
    }

    &:focus {
      color: white;
      border-color: transparent !important;
    }
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }

  .modal {
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);

    .modal-card-head {
      background: #ffffff;
      border-bottom: none;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      button {
        margin: 0 0 0 auto;
      }
    }

    .modal-card-body {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      background: #ffffff;
      padding: 80px 30px 100px 30px;

      p {
        font-family: $family-second;
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: center;
        color: #4a4a4a;
      }

      .center-icon {
        text-align: center;
        margin-bottom: 20px;
      }

      .icon {
        background: linear-gradient(137deg, $verde-get, #15bfaf 50%, $azul-get);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: initial;
        font-size: 120px;
      }
    }
  }
}

@include mobile {
  #inscricao {
    .box {
      padding: 50px 18px;
    }
  }
}

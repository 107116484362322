//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';
@import 'bulma/bulma';

#info {
  margin-top: 113px;

  .titulo {
    color: $cinza_get;
    font-size: 24px;
  }

  .texto {
    color: $cinza_get;
    font-family: $family-serif;
    font-size: 18px;
    margin-bottom: 15px;
  }

  .img-principal {
    width: 100%;
    margin-top: 55px;
  }
}
//-------------------
// Variaveis
//-------------------
@import "./src/assets/styles/variables.scss";

#desenvolvimento {
  margin-top: 100px;

  hr {
    background-color: #dbdbdb;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .hr-sections {
    margin-top: 100px;
  }

  .margin-hr {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  #desenvolvimento {
    .hr-sections {
      margin-bottom: 0px;
    }
  }
}

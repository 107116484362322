@import "./src/assets/styles/variables.scss";
@import "bulma/bulma";

#treinamento {
  .button-align {
    text-align: center;
  }

  .icon {
    background: linear-gradient(137deg, $verde-get, #15bfaf 50%, $azul-get);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial;
    font-size: 35px;
    vertical-align: -8px;
    margin-right: 15px;
  }

  .title {
    font-family: $family-second;
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.04;
    letter-spacing: normal;
    color: #4a4a4a;
  }

  .description {
    font-family: $family-second;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.64);
  }

  .mail {
    color: $azul_get;
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }

  .mail:hover {
    color: $verde_get;
  }

  .margin {
    margin-top: 10px;
  }

  .instructor {
    margin-bottom: 40px;

    .image {
      width: 105px;
      margin-right: 15px;
    }

    .name {
      margin-top: 0px;
      font-family: $family-second;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.64);
      margin-bottom: 5px;
    }

    .description {
      margin-top: 0px !important;
    }

    .margin-turma {
      margin-top: 15px !important;
    }

    .no-padding {
      padding-top: 0px;
    }
  }

  .init {
    .description {
      margin-top: 10px;
      margin-left: 50px;
    }
  }

  .more {
    .description {
      margin-top: 15px;
    }
  }

  .margin-between {
    margin-top: 30px;
  }

  .margin-icon {
    margin-bottom: 10px;
  }

  .content {
    white-space: pre-line !important;
  }

  .margin-button {
    margin-top: 50px;
  }

  .is-red {
    color: red;
  }
}

@include mobile {
  #treinamento {
    .instructor {
      .image {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

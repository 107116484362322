//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#card {
  text-align: center;
  .color {
    background: #eceff1;
  }

  .espacamento {
    margin-top: 59px;
  }

  .imagem{
    padding-top: 50px;
  }
  
  .texto {
    font-family: $family-serif;
    font-size: 18px;
  }

  .titulo {
    font-size: 24px;
    margin-top: 44px;
  }

}
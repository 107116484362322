//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#imagensComponent {
  margin-bottom: 70px;

  .subtitulo {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.8px;
    color: #6bb12e;
    margin-bottom: 15px;
  }

  .descricao {
    font-family: $family-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.54);
  }

  img {
    width: 100%;
  }
}
@import "./src/assets/styles/variables.scss";

#blogpost {
  padding-top: 100px;
  padding-bottom: 100px;
  img {
    max-height: 60vh;
  }
  .title {
    margin-top: 40px;

    h1 {
      font-size: 34px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: -2.3px;
      text-align: justify;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  .body {
    p,
    li,
    ol {
      font-family: Roboto;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      // text-align: left;
      text-align: justify !important;
      color: rgba(0, 0, 0, 0.64);
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;

      strong {
        font-family: Roboto;
        font-weight: bold;
      }
    }

    ol {
      padding-left: 20px;
    }
    li {
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    img {
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }

  hr {
    background-color: #d8d8d8;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .relacionadas {
    .title {
      margin-top: 0px;
    }

    .title-section {
      margin-top: 40px;

      h1 {
        font-size: 34px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: -2.3px;
        text-align: left;
        color: rgba(0, 0, 0, 0.87);
      }
    }
  }

  .card-alert .box {
    border-radius: 4px;
    background: #f9f9fa;
    padding: 80px 30px;

    p {
      font-family: $family-second;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
    }

    .center-icon {
      text-align: center;
      margin-bottom: 20px;
    }

    .icon {
      background: linear-gradient(137deg, $verde-get, #15bfaf 50%, $azul-get);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: initial;
      font-size: 120px;
    }
  }
}

@import "./src/assets/styles/variables.scss";

#footer {
  background-color: #ecf1f1 !important;
  padding-top: 50px;

  h1 {
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.64);
    margin-bottom: 25px;
  }

  ul li {
    margin-bottom: 15px;
  }

  a,
  p {
    font-size: 14px;
  }

  p {
    color: black;
  }

  a {
    color: $azul_get;
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
  }

  a:hover {
    color: $verde_get;
  }

  .center {
    text-align: center;
  }

  .final-footer {
    padding-bottom: 15px;
  }
}

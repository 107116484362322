@import "./src/assets/styles/variables.scss";

* {
  font-family: "Roboto Mono", monospace;
}

.section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-margin-top {
  margin-top: 0px;
}

.no-padding-top {
  padding-top: 0px;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.fadeOut {
  opacity: 0;
  width: 0;
  height: 0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
  opacity: 1;
  width: auto;
  height: auto;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

#home {
  #banner {
    .bg {
      height: 513px;
    }

    h1 {
      font-family: "Roboto";
      font-size: 48px;
      font-weight: 300 !important;
      letter-spacing: -0.5px;
      line-height: 1.17;
      text-align: center;
      padding-top: 83px;
    }
  }

  #quadros {
    margin-top: -55px;

    .box {
      box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
        0 0 0 1px rgba(10, 10, 10, 0.1);
      border-radius: 2px;
    }

    .columns {
      max-width: 592px;
      margin: 0 auto;
      text-align: center;
      padding: 0;
    }

    .column {
      padding: 15px;

      &:hover {
        background: whitesmoke !important;
      }
    }
    // .column {
    //   background: black !important;
    // }

    // Se não for mobile, coloca as bordas no meio
    @media (min-width: 768px) {
      .border-center {
        border-left: 1px solid #eceff1;
        border-right: 1px solid #eceff1;
      }
    }

    i {
      color: $verde_get;
    }

    p {
      color: $cinza_get;
      font-size: 15px;
      font-weight: 500;
    }
  }

  #dev-schole-spa-infra-getinfo {
    padding-top: 80px;
    padding-bottom: 80px;

    h1 {
      background-image: linear-gradient(to right, $verde_get, $azul_get);
      color: transparent;
      -webkit-background-clip: text;
      font-size: 34px;
      line-height: 1.3;
      letter-spacing: -2.3px;
      font-weight: normal;
      margin-top: 65px;
    }

    .no-margin-title {
      margin-top: 0px;
    }

    .getinfo {
      margin-bottom: 30px;
    }

    .margin-img {
      margin-top: 60px;
    }

    .img-column {
      text-align: center;
    }

    .img-column img {
      width: auto;
      max-width: 100%;
    }

    .mdi-icon {
      fill: #9b9b9b !important;
      height: 24px !important;
      margin-right: 10px;
      display: table-cell;
      vertical-align: middle;
    }

    .icons {
      font-size: 18px;
      color: #9b9b9b;
      display: table;
    }

    span {
      display: table-cell;
      vertical-align: middle;
      color: #9b9b9b;
    }

    .description {
      font-family: "Roboto";
      font-size: 18px;
      line-height: 1.39;
      color: #9b9b9b;
    }

    .social {
      .icon {
        fill: $verde_get !important;
        -webkit-transition: 0.2s ease-in;
        -moz-transition: 0.2s ease-in;
        -o-transition: 0.2s ease-in;
        transition: 0.2s ease-in;
      }

      .facebook:hover {
        fill: #4267b2 !important;
      }

      .linkedin:hover {
        fill: #0073b1 !important;
      }

      .instagram:hover {
        fill: #db236c !important;
      }

      .twitter:hover {
        fill: #0084b4 !important;
      }
    }

    .carousel-component {
      max-width: 380px;
      width: 100%;
      margin-top: 30px;

      .slider-wrapper,
      .slider-wrapper ul li {
        background: transparent;
        background: white;
      }

      .carousel-div {
        max-width: 100%;
        padding-bottom: 60px;
      }

      .control-dots .dot {
        background: #9b9b9b;
        width: 8px;
        height: 8px;
        opacity: 1;
      }

      .control-dots .selected {
        background-image: linear-gradient(107deg, #00d29b, #448aff);
        border-radius: 50px !important;
        width: 20px;
      }
    }
  }

  .treinamentos {
    h1 {
      margin-top: 0px !important;
    }

    img {
      margin-top: 60px;
      max-width: 400px !important;
      width: 100% !important;
    }
  }

  #clientes {
    .grayscale {
      filter: grayscale(100%);
    }
    padding-top: 80px;
    padding-bottom: 80px;

    h1 {
      background-image: linear-gradient(to right, $verde_get, $azul_get);
      color: transparent;
      -webkit-background-clip: text;
      width: 300px;
      font-size: 34px;
      line-height: 1.3;
      letter-spacing: -2.3px;
      font-weight: normal;
      margin-bottom: 30px;
    }

    .center-div {
      display: table;
      width: 100%;
    }

    .center-item {
      display: table-cell;
      text-align: center;
      vertical-align: middle;
    }

    .column1 img {
      max-height: 55px;
      width: auto;
    }

    .column2 {
      margin-top: 30px;
      margin-bottom: 30px;

      img {
        max-height: 55px;
        width: auto;
      }
    }

    .column3 img {
      max-height: 55px;
      width: auto;
    }

    // Custom Heights Logos
    .maxHeight30 {
      max-height: 30px !important;
    }

    .maxHeight40 {
      max-height: 40px !important;
    }

    .maxHeight50 {
      max-height: 50px !important;
    }

    .maxHeight60 {
      max-height: 60px !important;
    }

    .maxHeight70 {
      max-height: 70px !important;
    }

    .maxHeight80 {
      max-height: 80px !important;
    }

    .maxHeight90 {
      max-height: 90px !important;
    }

    .full-list-button {
      margin-top: 15px;

      a {
        color: black;
        text-transform: uppercase;
      }

      .mdi-icon {
        vertical-align: -5px;
      }
    }
  }
}

// Mobile
@media (max-width: 768px) {
  #home {
    #banner h1 {
      font-size: 34px;
    }

    #quadros hr {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    #dev-schole-spa-infra-getinfo {
      h1 {
        margin-top: 0px;
      }

      .infra h1 {
        margin-top: 25px;
      }

      .getinfo {
        img {
          max-width: 250px !important;
        }

        .img-column {
          text-align: left;
        }

        .margin-img {
          margin-top: 0px;
        }
      }

      .carousel-component {
        margin-top: 0px;
      }
    }

    #clientes {
      .center-div {
        display: block;
        width: 100%;
      }

      img {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .center-item {
        display: block;
        text-align: center;
        vertical-align: none;
      }

      .column2 {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .full-list-button {
        text-align: center;
      }
    }
  }
}

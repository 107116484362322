#artigo-destaque {
  padding: 30px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 transparent, transparent;
  border: solid 0.5px transparent;
  background: transparent;

  .title {
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: -1.6px;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
  }

  .text {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.4;
    letter-spacing: normal;
    // text-align: left;
    text-align: justify;
    color: rgba(0, 0, 0, 0.64);
  }

  &:hover {
    border-radius: 2px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border: solid 0.5px transparent;
    background: linear-gradient(#eceff1, #eceff1),
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) 80%,
        rgba(0, 0, 0, 0.02) 95%,
        rgba(0, 0, 0, 0.04)
      );
  }
}

//-------------------
// Variaveis
//-------------------
@import "./src/assets/styles/variables.scss";

#imagem-component {
  .espacamento {
    padding-bottom: 50px;
  }

  .titulo {
    font-size: 24px;
    margin-top: 30px;
  }

  .texto {
    font-family: $family-serif !important;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  #imagem-component {
    text-align: center;

    .img {
      max-height: 500px;
      height: 100%;
      width: auto;
      margin-top: 30px;
    }
  }
}

@import "./src/assets/styles/variables.scss";
@import "~bulma/bulma";

#treinamentos-card {
  margin-bottom: 50px;

  .bg-color {
    background-color: #eceff1;
    padding: 50px;
  }

  .title {
    font-size: 28px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -1.5px;
  }

  .image-align {
    text-align: right;
    margin-right: 30px;
  }

  .description {
    font-family: $family-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.64);
  }

  .button-align {
    text-align: center;
  }
}

@include mobile {
  #treinamentos-card {
    .image-align {
      text-align: center;
      margin-right: 0px;
    }
  }
}

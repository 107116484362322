@import './src/assets/styles/variables.scss';
@import 'bulma/bulma';

#download-app {
  margin-top: 20px;
  margin-bottom: 100px;

  h1 {
    font-size: 28px;
    text-align: center;
    color: $azul-get;
  }

  .margin {
    margin-top: 50px;
  }

  .icone {
    color: #9b9b9b;
    fill: #9b9b9b;
    font-size: 50px;
    vertical-align: -10px;
  }

  .texto {
    margin-left: 15px;
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.6px;
    color: #9b9b9b;
  }

  a {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@include mobile {
  #download-app {
    .display {
      display: block;
      text-align: center;
    }

    a {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
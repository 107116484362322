//-----------------------
// Main Style Sheet
//-----------------------

//-----------------------
// Variaveis
//-----------------------
@import "./src/assets/styles/variables.scss";

//-----------------------
// Estilos de terceiros
//-----------------------
@import "~bulma/bulma";
// @import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono');
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono:300,400,700|Roboto:300,400,700");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

//-----------------------
// Base
//-----------------------
body {
  background: white;
}

.no-break {
  white-space: nowrap;
}

.main {
  min-height: calc(100vh - 422px);
}

.uppercase {
  text-transform: uppercase;
}

button {
  outline: none;
}

strong {
  font-weight: bold;
}

.margin-sections {
  margin-top: 120px;
  margin-bottom: 100px;
}

@include tablet-only {
  #g-recaptcha {
    transform: scale(0.75);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}

@include mobile {
  #g-recaptcha {
    transform: scale(0.75);
    transform-origin: left top;
    -webkit-transform-origin: left top;
  }
}

//-----------------------
// General
//-----------------------
@import "./src/assets/styles/general/header.scss";
@import "./src/assets/styles/general/footer.scss";
@import "./src/assets/styles/general/button.scss";

//-----------------------
// Views
//-----------------------
@import "./src/assets/styles/views/home.scss";

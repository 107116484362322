#treinamentos {
  margin-top: 100px;
  margin-bottom: 100px;

  hr {
    margin-top: 40px;
    margin-bottom: 100px;
  }
}

#treinamentos-list {
  margin-top: 100px;
  margin-bottom: 0px;

  #servico {
    margin-bottom: 60px;
  }

  .margin-bottom {
    margin-bottom: 100px;
  }

  hr {
    margin-top: 40px;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  #treinamentos-list { 
    #ad {
      margin-top: 0px;
    }

    .titulo-ad {
      padding-top: 0px;
    }
  }
}
@import "./src/assets/styles/variables.scss";

.button-leaked-active {
  border-image-slice: 1;
  border-image-source: linear-gradient(
    103deg,
    $verde-get,
    #15bfaf 50%,
    $azul-get
  );
  border-style: solid;
  border-width: 2px;
  border-radius: 2px;
  color: $verde-button;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 10px 5px 10px;
  min-width: 138px;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;

  i {
    margin-left: 5px;
    margin-right: 0px;
    vertical-align: -6px;
  }

  &:hover {
    color: white;
    background-image: linear-gradient(109deg, $verde_get, $azul_get);
  }
}

.button-leaked-disable {
  border: 2px solid $disable;
  border-radius: 2px;
  color: $disable;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 3px 10px 5px 10px;
  min-width: 138px;
}

//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#pionet {
 .title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fe121c;
  }

  .descricao {
    color: rgba(0, 0, 0, 0.54);
    font-family: $family-serif;
    font-size: 20px;
    text-align: left;
  }
}
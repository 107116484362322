//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#ad {
  margin-top: 80px;

  .imagem-ad {
    margin-bottom: 0px;
    padding-bottom: 6px;
  }

  .titulo-ad {
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(to right, #00d29b, #448aff);
    color: transparent;
    font-size: 34px;
    padding-top: 65px;
    padding-bottom: 15px;
    margin-bottom: 5px;
    white-space: pre-line !important;
  }
  
  @media (max-width: 768px) {
    .alinhamento {
      text-align: center;
    }

    .imagem-ad {
      margin-top: 50px;
    }
  }

  @media (min-width: 769px) {
    .adtitle {
      padding-left:30px !important;
    }
  }

  @media (min-width: 769px) and (max-width: 851px) {
    .titulo-ad {
      padding-top: 5px !important;
    }
  }

  @media (min-width: 769px) and (max-width: 930px) {
    .titulo-ad {
      font-size: 28px !important;
    }
  }
}

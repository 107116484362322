@import "./src/assets/styles/variables.scss";

#page404 {
  margin-top: -5px;

  .is-gradient {
    background: linear-gradient(
      141deg,
      $verde_get 0%,
      $azul_get 75%
    ) !important;
  }

  h1,
  h2 {
    color: white;
  }
}

@import 'bulma/bulma';

#projeto {
  .box {
    background-color: #ecf1f1;
    text-align: center;
    margin-bottom: 0px;
    margin-left: 15px;
    margin-right: 15px;
    -webkit-transition: 0.2s ease-in;
    -moz-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;

    &:hover {
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    }
  }

  .title-card {
    font-size: 15px;
  }

  .image-card {
    height: auto;
    width: 100%;
  }

  @include mobile {
    .box {
      margin-bottom: 30px;
    }
  }
}

@media (min-width: 941px) and (max-width: 1407px) {
  #projeto {
    .title-height {
      min-height: 44px;
    }
  }
}

@media (max-width: 768px) {
  #projeto {
    .box {
      margin: 0 auto;
      max-width: 300px;
    }
  }
}
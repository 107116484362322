//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#android-ios-web {
  .apple {
    fill: #9b9b9b;
    vertical-align: -4px;
  }
  
  .espacamento {
    margin-top: 50px;
  }

  .icone {
    color: #9b9b9b;
    font-size: 24px;
    vertical-align: -6px;
  }

  .texto {
    color: #9b9b9b;
    font-family: $family-mono;
    font-size: 18px;
    padding-left: 10px;
  }
}

@import "bulma/bulma";
@import "./src/assets/styles/variables.scss";

#covid {
  background-color: #f9f9fa;
  padding-top: 100px;
  padding-bottom: 100px;

  h1 {
    margin-bottom: 50px;
  }

  .covid-container {
    position: relative;
    overflow: hidden;
    padding-top: 69.66%; // altura / largura * 100 (650/933*100)
  }

  .covid-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 30px 30px 30px 30px;
  }

  @include touch {
    .covid-panel {
      margin: 0 auto;
    }
  }
}

@import "bulma/bulma";
@import "./src/assets/styles/variables.scss";

#blog {
  padding-top: 100px;
  padding-bottom: 100px;

  hr {
    background-color: #d8d8d8;
    margin-top: 40px;
    margin-bottom: 50px;
  }

  .next-previous {
    margin-top: 30px;
  }

  .card-alert .box {
    border-radius: 4px;
    background: #f9f9fa;
    padding: 80px 30px;

    p {
      font-family: $family-second;
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: center;
      color: #4a4a4a;
    }

    .center-icon {
      text-align: center;
      margin-bottom: 20px;
    }

    .icon {
      background: linear-gradient(137deg, $verde-get, #15bfaf 50%, $azul-get);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      display: initial;
      font-size: 120px;
    }
  }
}

//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#servico {
  .pagina {
    margin: 0px;
  }

  .icone {
    font-size: 36px;
    vertical-align: -8px;
  }

  .gradient-color {
    background: linear-gradient(130deg, #00d29b, #15bfaf 50%, #448aff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: initial; /* reset Font Awesome’s display:inline-block */
  }

  .titulo {
    color: $cinza_get;
    font-size: 24px;
    padding-left: 10px;
  }

  .texto {
    color: $cinza_get;
    font-family: $family-serif;
    font-size: 18px;
    margin-bottom: 15px;
  }
}
//-------------------
// Variaveis
//-------------------
@import "./src/assets/styles/variables.scss";

#governanca {
  margin-top: 100px;

  .governanca {
    margin-top: 45px;

    .titulo {
      font-size: 24px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.8px;
      color: $azul_get;
      margin-bottom: 15px;
    }

    .descricao {
      font-family: $family-serif;
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.54);
    }

    img {
      width: 100%;
    }
  }

  .final {
    margin-bottom: 100px;
  }

  hr {
    background-color: #babdbe;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

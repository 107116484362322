//-------------------
// Variaveis
//-------------------
@import './src/assets/styles/variables.scss';

#fale-conosco {
  padding-top: 50px;
  padding-bottom: 50px;

  .pagina {
    margin: 0px;
  }

  .texto {
    font-size: 24px;
  }

  .contato {
    font-family: 'Roboto Mono', monospace;
    text-transform: uppercase;
    color: white;
    background-image: linear-gradient(109deg, $verde_get, $azul_get);
    border-radius: 2px;
    border: none;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    height: 32px;
    line-height: 1;
  }
}